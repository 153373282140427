<template>
  <div style="margin-left: 16px;margin-right: 15px;text-align: center;height: 110vh;">
    <van-row type="flex" justify="space-between" style="margin-top: 0px;">
      <!--      -->
      <van-col span="8" class="info-title-left">商户图标</van-col>
      <van-col span="16"><img class="info-content-right-icon" :src="merLogo"></van-col>
      <van-col span="24" class="split-line"></van-col>
      <van-col span="8" class="info-title-left">商户名称</van-col>
      <van-col span="16" class="info-content-right">{{ merName }}</van-col>
      <van-col span="24" class="split-line"></van-col>
      <van-col span="8" class="info-title-left">商户类型</van-col>
      <van-col span="16" class="info-content-right">{{ merType }}</van-col>
      <van-col span="24" class="split-line"></van-col>
      <van-col span="8" class="info-title-left">企业名称</van-col>
      <van-col span="16" class="info-content-right">{{ companyName }}</van-col>
      <van-col span="24" class="split-line"></van-col>
      <van-col span="8" class="info-title-left">企业证件号码</van-col>
      <van-col span="16" class="info-content-right">{{ companyCertNo }}</van-col>
      <van-col span="24" class="split-line"></van-col>
      <van-col span="8" class="info-title-left">企业证件类型</van-col>
      <van-col span="16" class="info-content-right">{{ companyCertType }}</van-col>
      <van-col span="24" class="split-line"></van-col>

      <van-col span="8" class="info-title-left">商户唯一编号</van-col>
      <van-col span="16" class="info-content-right">{{ companyId }}</van-col>
      <van-col span="24" class="split-line"></van-col>
      <van-col span="8" class="info-title-left">商户地址</van-col>
      <van-col span="16" class="info-content-right">{{ merAddress }}</van-col>
      <van-col span="24" class="split-line"></van-col>
      <van-col span="8" class="info-title-left">联系人</van-col>
      <van-col span="16" class="info-content-right">{{ merContactName }}</van-col>
      <van-col span="24" class="split-line"></van-col>
      <van-col span="8" class="info-title-left">联系电话</van-col>
      <van-col span="16" class="info-content-right">{{ merContactPhone }}</van-col>
      <van-col span="24" class="split-line"></van-col>
      <van-col span="8" class="info-title-left">商户号</van-col>
      <van-col span="16" class="info-content-right">{{ merId }}</van-col>

      <van-col span="24" class="split-line"></van-col>


      <van-col span="8" class="info-title-left">证件号码</van-col>
      <van-col span="16" class="info-content-right">{{ certificateNo }}</van-col>
      <van-col span="24" class="split-line"></van-col>
      <van-col span="8" class="info-title-left">证件类型</van-col>
      <van-col span="16" class="info-content-right">{{ certificateType }}</van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  name: "user_info",
  data() {
    return {
      companyCertNo: "",
      companyCertType: "",
      companyName: "",
      companyId: "",
      merAddress: "",
      merContactName: "",
      merContactPhone: "",
      merId: "",
      merLogo: "",
      merName: "",
      merType: "",
      certificateNo: "",
      certificateType: "",

    }
  },
  methods: {
    get_detail() {
      // this.$route.query.commissionFee
      this.companyCertNo = this.$route.query.companyCertNo
      this.companyName = this.$route.query.companyName
      this.companyId = this.$route.query.companyId
      this.merAddress = this.$route.query.merAddress
      this.merContactName = this.$route.query.merContactName
      this.merContactPhone = this.$route.query.merContactPhone
      this.merId = this.$route.query.merId
      this.merLogo = this.$route.query.merLogo
      this.merName = this.$route.query.merName
      this.certificateNo = this.$route.query.certificateNo

      // console.log(this.merLogo)
      if (this.merLogo == "" || this.merLogo == null || this.merLogo == ""){
        this.merLogo = require("../../../assets/image/pay/icon_nonghang.png")
      }


      switch (this.$route.query.merType) {
        case "1":
          this.merType = "个人商户";
          break;
        case "2":
          this.merType = "企业";
          break;
        case "3":
          this.merType = "个体工商户";
          break;
        default:
          this.merType = "";
      }

      switch (this.$route.query.companyCertType) {
        case "U":
          this.companyCertType = "营业执照";
          break;
        case "Y":
          this.companyCertType = "组织机构代码证";
          break;
        case "V":
          this.companyCertType = "统一社会信用代码";
          break;
        default:
          this.companyCertType = "";
      }

      switch (this.$route.query.certificateType) {
        case "I":
          this.certificateType = "身份证";
          break;
        case "P":
          this.certificateType = "护照";
          break;
        case "G":
          this.certificateType = "军官证";
          break;
        case "U":
          this.certificateType = "其他证件";
          break;
        default:
          this.certificateType = "";
      }
    },
  },
  mounted() {
    this.get_detail()
  },
  created() {
    document.title = "基本信息"
  },
}
</script>

<style scoped>
.info-title-left {
  text-align: left;
  color: #707070;
  margin-top: 18px;
}

.info-content-right {
  text-align: right;
  margin-top: 18px;
}

.info-content-right-icon{
  width: 32px;height: 32px;margin-top: 16px; display: block;margin-right: .5rem;flex: 0 0 auto;float: right;
}

.split-line {
  height: 1px;
  margin-top: 16px;
  margin-bottom: 0px;
  background-color: #F6F6F6;
}
</style>